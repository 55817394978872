import Link from 'next/link';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ComponentPropsWithoutRef } from 'react';

interface AnimatedLink {
  href?: string;
  linkText: string;
  className?: string;
  hrefTarget?: ComponentPropsWithoutRef<'a'>['target'];
}

export function AnimatedLink({ href, linkText, className, hrefTarget = '_self' }: AnimatedLink) {
  const linkClasses = clsx('group flex w-fit items-center justify-center gap-2', className);

  return href ? (
    <Link className={linkClasses} href={href} target={hrefTarget}>
      <span className="underline-text-transition-blue">{linkText}</span>
      <ArrowRightIcon className="mt-0.5 size-5 transition-transform duration-300 group-hover:translate-x-1 group-hover:text-gradle-blue" />
    </Link>
  ) : (
    <div className={linkClasses}>
      <span className="underline-text-transition-blue">{linkText}</span>
      <ArrowRightIcon className="mt-0.5 size-5 transition-transform duration-300 group-hover:translate-x-1 group-hover:text-gradle-blue" />
    </div>
  );
}
