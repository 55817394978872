import React from 'react';
import { PLAYWRIGHT_IGNORE } from '@/lib/constants';
import FooterBottomNav from './FooterBottomNav';
import { FooterBottomNavLinks } from '@/components/Footer/footer.copy';
import Logo from '@/components/Logo';

const NarrowFooter = () => {
  return (
    <section
      className={
        'text-31xl font-h2-lg overflow-x:hidden box-border flex h-auto w-full items-center justify-center p-3 text-center text-gray-1 md:h-12 md:p-0'
      }
    >
      <div className="flex w-full max-w-7xl flex-col items-center justify-between px-4 md:flex-row">
        <div className="order-last flex w-72 flex-none items-center justify-center md:order-first md:justify-start">
          <p className={'text-black'}>© Gradle Inc. 2024</p>
        </div>
        <div className="flex flex-1 shrink items-center justify-center" data-testid={PLAYWRIGHT_IGNORE}>
          <Logo
            width={50}
            height={53}
            alt={'Gradle Elephant Icon'}
            src={'/assets/images/open-source/iconElephant.svg'}
            className="size-10"
          />
        </div>
        <FooterBottomNav navLinks={FooterBottomNavLinks} />
      </div>
    </section>
  );
};

export default NarrowFooter;
