import { useState, useEffect, Fragment } from 'react';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { navigationBarItems } from './data';
import GradleElephantWithText from '@/components/Logos/GradleElephantWithText';
import Button from '@/components/Button';
import Link from 'next/link';

import DesktopNavbarDrawer from './DesktopNavbarDrawer';
import MobileNavbarDrawer from './MobileNavbarDrawer';

const horizontalLineClasses = 'h-1 w-7 my-[3px] rounded-full bg-white';

const HamburgerMenu = () => {
  return (
    <div className="flex flex-col items-center justify-center p-2 lg:hidden">
      <div className={horizontalLineClasses} />
      <div className={horizontalLineClasses} />
      <div className={horizontalLineClasses} />
    </div>
  );
};

const NavigationBar = () => {
  const [menuOpenIdx, setMenuOpenIdx] = useState<number | null>(null);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const pathname = usePathname();

  const handleMouseEnterMenu = (href: string | undefined, idx: number) => () => {
    href ? setMenuOpenIdx(null) : setMenuOpenIdx(idx);
  };

  const handleMouseOutCloseMenu = () => {
    setMenuOpenIdx(null);
    setMobileNavOpen(false);
  };

  useEffect(() => {
    if (menuOpenIdx != null || mobileNavOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menuOpenIdx, mobileNavOpen]);

  useEffect(() => {
    // close nav on navigation complete trigger
    handleMouseOutCloseMenu();
  }, [pathname]);

  return (
    <nav className="bg-[#161617] px-4 py-4 text-white md:px-10" onMouseLeave={handleMouseOutCloseMenu}>
      <div className="max-w-section flex justify-between">
        <h1>
          <Link className="mega-menu-link" href="/">
            <GradleElephantWithText className="mb-3 mt-1" />
          </Link>
        </h1>
        <div className="hidden gap-5 lg:flex">
          <ul className="text-input-label flex h-full items-center gap-4">
            {navigationBarItems.map((item, idx) => (
              <Fragment key={item.leftNavSection.title}>
                {item.href ? (
                  <Link
                    href={item.href}
                    onMouseEnter={handleMouseEnterMenu(item.href, idx)}
                    className={'mega-menu-link flex h-7 items-center px-1 hover:text-light-blue'}
                  >
                    {item.leftNavSection.title}
                  </Link>
                ) : (
                  <li
                    className={clsx(
                      'flex h-7 items-center px-1 hover:text-light-blue',
                      item.href ? 'cursor-pointer' : 'cursor-default',
                      menuOpenIdx === idx ? 'text-light-blue' : 'text-white',
                    )}
                    onMouseEnter={handleMouseEnterMenu(item.href, idx)}
                  >
                    {item.leftNavSection.title}
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
          <Button
            href="/develocity/trial"
            className="mega-menu-link"
            onMouseEnter={() => {
              setMenuOpenIdx(null);
            }}
          >
            Request a Trial
          </Button>
        </div>
        <div className="flex items-center justify-center lg:hidden">
          <button onClick={() => setMobileNavOpen(prev => !prev)}>
            {mobileNavOpen ? <XMarkIcon className="size-6" /> : <HamburgerMenu />}
          </button>
        </div>
      </div>

      <MobileNavbarDrawer mobileNavOpen={mobileNavOpen} setMenuOpenIdx={setMenuOpenIdx} menuOpenIdx={menuOpenIdx} />

      <DesktopNavbarDrawer menuOpenIdx={menuOpenIdx} handleMouseOutCloseMenu={handleMouseOutCloseMenu} />
    </nav>
  );
};
export default NavigationBar;
