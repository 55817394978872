import React, { Fragment } from 'react';

export type FooterBottomNavItem = {
  name: string;
  href: string;
};

export type FooterBottomNavProps = {
  navLinks: FooterBottomNavItem[];
};

const FooterBottomNav = ({ navLinks }: FooterBottomNavProps) => {
  return (
    <div className="flex w-72 flex-none items-center justify-center pb-2 pt-3 md:justify-end md:pb-0 md:pt-0">
      <div className="text-black [&_span]:mx-4">
        {navLinks.map((item: FooterBottomNavItem, index: number) => (
          <Fragment key={item.name}>
            <a href={item.href}>{item.name}</a>
            {index < navLinks.length - 1 && <span>|</span>}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default FooterBottomNav;
