import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Url } from 'next/dist/shared/lib/router/router';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';

export type LogoProps = {
  width?: number;
  height?: number;
  href?: Url | string;
  src: string | StaticImport;
  alt?: string;
  className?: string;
  containerClassName?: string;
  layout?: string;
};

const Logo: React.FC<LogoProps> = ({ href, src, alt = '', width, height, className, containerClassName }) => {
  const ImageElement = <Image src={src} alt={alt} width={width} height={height} className={className} />;

  return <div className={containerClassName}>{href ? <Link href={href}>{ImageElement}</Link> : ImageElement}</div>;
};

export default Logo;
