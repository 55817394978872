import React from 'react';
import FatFooter from './FatFooter';
import NarrowFooter from './NarrowFooter';

interface FooterProps {
  isMini?: boolean;
}

const Footer = ({ isMini = false }: FooterProps) => {
  return (
    <footer>
      {!isMini && <FatFooter />}
      <NarrowFooter />
    </footer>
  );
};

export default Footer;
