import { Fragment } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import Button from '@/components/Button';
import { noop } from '@/lib/constants';
import { useBannerState } from '@/lib/contexts/BannerContext';
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { navigationBarItems, subMenuItems } from './data';

type MobileNavbarDrawerProps = {
  mobileNavOpen: boolean;
  setMenuOpenIdx: (idx: number | null) => void;
  menuOpenIdx: number | null;
};

const MobileNavbarDrawer = ({ mobileNavOpen, setMenuOpenIdx, menuOpenIdx }: MobileNavbarDrawerProps) => {
  const { isBannerOpen } = useBannerState();

  return (
    <div
      className={clsx(
        'absolute left-0 h-svh w-full overflow-y-scroll bg-[#161617] px-4 py-10 md:px-10 lg:hidden',
        mobileNavOpen ? 'block' : 'hidden',
        isBannerOpen
          ? 'top-nav-with-banner max-h-[calc(100svh_-_var(--navbarBannerMobileHeight)_-_var(--navbarMobileHeight))] md:max-h-[calc(100svh_-_var(--navbarBannerHeight)_-_var(--navbarMobileHeight))]'
          : 'top-nav-without-banner max-h-[calc(100svh_-_var(--navbarMobileHeight))]',
      )}
    >
      <div className="relative flex h-full w-full flex-col gap-9">
        {navigationBarItems.map((item, idx) => (
          <Fragment key={item.leftNavSection.title}>
            {item.href ? (
              <Link className={'mega-menu-link text-h6 hover:text-light-blue'} href={item.href}>
                {item.leftNavSection.title}
              </Link>
            ) : (
              <div>
                <div
                  onClick={!item.href ? () => setMenuOpenIdx(menuOpenIdx === idx ? null : idx) : noop}
                  className={clsx('group flex h-8 cursor-pointer items-center justify-between')}
                >
                  <p
                    className={clsx('text-h6 group-hover:text-light-blue', {
                      'text-light-blue': menuOpenIdx === idx,
                    })}
                  >
                    {item.leftNavSection.title}
                  </p>
                  {!item.href && (
                    <ChevronRightIcon
                      className={clsx('size-6 transition-transform duration-300', menuOpenIdx === idx && 'rotate-90')}
                    />
                  )}
                </div>
                <div
                  className={clsx('grid transition-all', menuOpenIdx === idx ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]')}
                >
                  <div className={'flex flex-col gap-6 overflow-hidden'}>
                    <div className="border-b border-b-dark-background">
                      {item.leftNavSection.title === 'Products' && (
                        <h5 className="text-caption mt-12 text-gray-3">{item.leftNavSection.title}</h5>
                      )}
                      <div className="my-12 flex flex-col gap-10 ">
                        {item.leftNavSection.title === 'Products' ? (
                          <>
                            <div className="flex flex-col gap-8 border-b border-b-dark-background pb-12">
                              {item.leftNavSection.subMenuItems.map(subItem => (
                                <Fragment key={subItem.buttonContent}>
                                  {subItem.link ? (
                                    <Link className="mega-menu-link w-32" href={subItem.link.href}>
                                      {subItem.logo}
                                    </Link>
                                  ) : (
                                    <div>{subItem.logo}</div>
                                  )}
                                </Fragment>
                              ))}
                            </div>

                            <h5 className="text-caption text-gray-3">{item.rightNavSectionTitle}</h5>
                            {item.leftNavSection.subMenuItems.map(subItem => (
                              <div key={subItem.buttonContent} className="flex flex-col gap-10">
                                {subItem.menuItemKey &&
                                  subMenuItems['product'].map((subMenuItem, idx) => (
                                    <div key={`${subMenuItem.type}-${idx}`}>
                                      {subMenuItem.link ? (
                                        <Link
                                          className="mega-menu-link hover:text-light-blue hover:underline"
                                          href={subMenuItem.link.href}
                                        >
                                          {subMenuItem.title}
                                        </Link>
                                      ) : (
                                        <div>{subMenuItem.title}</div>
                                      )}
                                      {subMenuItem.items.map(subSubItem => (
                                        <ul key={subSubItem.title} className="mt-4 flex flex-col gap-4">
                                          <Link
                                            href={subSubItem.link.href}
                                            target={subSubItem.link.external ? '_blank' : undefined}
                                            key={subSubItem.title}
                                            className="mega-menu-link group flex items-center"
                                          >
                                            <li className="flex h-5 items-center text-gray-4 hover:text-gray-5">
                                              {subSubItem.title}
                                            </li>
                                            <ArrowRightIcon className="ml-2 size-3.5 text-light-blue opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                                          </Link>
                                        </ul>
                                      ))}
                                    </div>
                                  ))}
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            {item.leftNavSection.subMenuItems.map(subItem => (
                              <div className="text-body-bold" key={subItem.buttonContent}>
                                {subItem.link ? (
                                  <Link
                                    className="mega-menu-link underline-text-transition-blue"
                                    href={subItem.link.href}
                                  >
                                    {subItem.buttonContent}
                                  </Link>
                                ) : (
                                  <div>{subItem.logo ? subItem.logo : subItem.buttonContent}</div>
                                )}

                                <div className="flex flex-col gap-4">
                                  {subItem.menuItemKey &&
                                    subMenuItems[subItem.menuItemKey ?? 'product'].map((subMenuItem, idx) => (
                                      <div key={`${subMenuItem.type}-${idx}`}>
                                        <div>
                                          {subMenuItem.items.map(subSubItem => (
                                            <ul className="mt-4 flex flex-col gap-4" key={subSubItem.title}>
                                              <Link
                                                href={subSubItem.link.href}
                                                target={subSubItem.link.external ? '_blank' : undefined}
                                                key={subSubItem.title}
                                                className="mega-menu-link group flex items-center"
                                              >
                                                <li className="text-body flex h-5 items-center text-gray-4 hover:text-gray-5">
                                                  {subSubItem.title}
                                                </li>
                                                <ArrowRightIcon className="ml-2 size-3.5 text-light-blue opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                                              </Link>
                                            </ul>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        ))}

        <Button className="mega-menu-link mb-10 mt-14" href="/develocity/trial">
          Request a Trial
        </Button>
      </div>
    </div>
  );
};
export default MobileNavbarDrawer;
