'use client';

import clsx from 'clsx';
import Image from 'next/image';
import { ChevronRightIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { noop } from '@/lib/constants';
import { AnimatedLink } from '@/components/AnimatedLink';
import { SubMenuItemType } from './data';
import { navigationBarItems, subMenuItems } from './data';

type SubNavButtonProps = {
  item: SubMenuItemType;
  handleSubNavClick: (menuOpenIdx: number, idx: number) => void;
  menuOpenIdx: number;
  idx: number;
  getCurrentSubMenuOpenIdx: (menuOpenIdx: number) => number;
};

const SubNavButton = ({ item, handleSubNavClick, menuOpenIdx, idx, getCurrentSubMenuOpenIdx }: SubNavButtonProps) => {
  const buttonContent = (
    <>
      <div className="flex items-center gap-2">
        {item.logo ? (
          <div className="py-2.5">{item.logo}</div>
        ) : (
          <>
            {item.icon && (
              <item.icon
                className={clsx('size-6 group-hover:text-gradle-blue group-active:text-gradle-blue', {
                  'text-gradle-blue': idx === getCurrentSubMenuOpenIdx(menuOpenIdx),
                })}
              />
            )}
            <span
              className={clsx('group-hover:gradle-gradient-text text-body', {
                'gradle-gradient-text': idx === getCurrentSubMenuOpenIdx(menuOpenIdx),
              })}
            >
              {item.buttonContent}
            </span>
          </>
        )}
      </div>
      {item?.link || item?.buttonContent === 'Develocity' ? null : (
        <ChevronRightIcon className="size-5 transition-transform group-hover:translate-x-1" />
      )}
    </>
  );

  if (item.link) {
    return (
      <Link
        href={item.link.href}
        target={item.link.external ? '_blank' : undefined}
        onClick={item.link ? noop : () => handleSubNavClick(menuOpenIdx, idx)}
        className={clsx(
          'mega-menu-link group flex w-full items-center justify-between rounded-lg px-4 py-2 hover:bg-dark-background',
          {
            'bg-dark-background': idx === getCurrentSubMenuOpenIdx(menuOpenIdx),
          },
        )}
        key={item.buttonContent}
      >
        {buttonContent}
      </Link>
    );
  }

  return (
    <button
      onClick={item.link ? noop : () => handleSubNavClick(menuOpenIdx, idx)}
      className={clsx('group flex w-full items-center justify-between rounded-lg px-4 py-2 hover:bg-dark-background', {
        'bg-dark-background': idx === getCurrentSubMenuOpenIdx(menuOpenIdx),
      })}
      key={item.buttonContent}
    >
      {buttonContent}
    </button>
  );
};

type DesktopNavbarProps = {
  menuOpenIdx: number | null;
  handleMouseOutCloseMenu: () => void;
};

const DesktopNavbarDrawer = ({ menuOpenIdx, handleMouseOutCloseMenu }: DesktopNavbarProps) => {
  //    For each nav item that opens the nav drawer on hover, keep track
  //    of the index of the sub menu item that is currently open.
  const [subMenuTabOpenArray, setSubMenuTabOpenArray] = useState([0, 0, 0, 0]);

  const handleSubNavClick = (menuOpenIdx: number, idx: number) => {
    const currentOpenNav = navigationBarItems[menuOpenIdx].leftNavSection.title;

    //   Keep track of which tab is open depending
    //   on which nav item is currently open
    if (currentOpenNav === 'Products') {
      setSubMenuTabOpenArray([idx, 0, 0, 0]);
    } else if (currentOpenNav === 'Solutions') {
      setSubMenuTabOpenArray([0, idx, 0, 0]);
    } else if (currentOpenNav === 'Resources') {
      setSubMenuTabOpenArray([0, 0, idx, 0]);
    } else if (currentOpenNav === 'Company') {
      setSubMenuTabOpenArray([0, 0, 0, idx]);
    }
  };

  const getCurrentSubMenuOpenIdx = (menuOpenIdx: number) => {
    const currentOpenNav = navigationBarItems[menuOpenIdx].leftNavSection.title;

    if (currentOpenNav === 'Products') {
      return subMenuTabOpenArray[0];
    } else if (currentOpenNav === 'Solutions') {
      return subMenuTabOpenArray[1];
    } else if (currentOpenNav === 'Resources') {
      return subMenuTabOpenArray[2];
    } else if (currentOpenNav === 'Company') {
      return subMenuTabOpenArray[3];
    }

    return 0;
  };

  useEffect(() => {
    //  Reset the sub menu tab open array when the nav drawer is closed per design
    setSubMenuTabOpenArray([0, 0, 0, 0]);

    if (menuOpenIdx != null) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menuOpenIdx]);

  return (
    <>
      <div
        className={clsx(
          'absolute left-0 z-10 hidden h-96 w-full overflow-hidden bg-[#161617] px-10 transition-all lg:block',
          menuOpenIdx != null ? 'visible max-h-96' : 'max-h-0',
        )}
      >
        {menuOpenIdx != null && (
          <div className="max-w-section flex h-full py-10">
            <div className="flex flex-1 flex-col gap-6 pr-6">
              <div className="text-caption text-gray-3">{navigationBarItems[menuOpenIdx].leftNavSection.title}</div>
              <div className="flex flex-col gap-6">
                {navigationBarItems[menuOpenIdx].leftNavSection?.subMenuItems?.map((item, idx) => (
                  <SubNavButton
                    key={item.buttonContent}
                    item={item}
                    idx={idx}
                    handleSubNavClick={handleSubNavClick}
                    menuOpenIdx={menuOpenIdx}
                    getCurrentSubMenuOpenIdx={getCurrentSubMenuOpenIdx}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-[4] flex-col gap-4 border-l-[1px] border-dark-background pl-6">
              <div className="text-caption text-gray-3">{navigationBarItems[menuOpenIdx].rightNavSectionTitle}</div>
              <div className="flex h-full">
                {subMenuItems[
                  navigationBarItems[menuOpenIdx].leftNavSection.subMenuItems[getCurrentSubMenuOpenIdx(menuOpenIdx)]
                    ?.menuItemKey ?? 'product'
                ]?.map((item, idx) => (
                  <div key={`nav-${idx}`} className="flex h-full w-full gap-2">
                    {item.icon && <item.icon className="size-6 text-gradle-blue" />}
                    {item.type === 'links' ? (
                      <div className="flex flex-col gap-6">
                        <Link
                          href={item.link?.href ?? '/'}
                          className="mega-menu-link text-body-bold underline-offset-4 transition-all hover:text-gradle-blue hover:underline"
                        >
                          {item.title}
                        </Link>
                        <ul className="text-footnote flex flex-col gap-4 text-gray-4">
                          {item.items.map(subItem => (
                            <Link
                              href={subItem.link.href}
                              target={subItem.link.external ? '_blank' : undefined}
                              key={subItem.title}
                              className="mega-menu-link group flex items-center"
                            >
                              <li className="flex h-5 items-center group-hover:text-gray-5">{subItem.title}</li>
                              <ArrowRightIcon className="ml-2 size-3.5 text-light-blue opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                            </Link>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="flex gap-4">
                        {item.items.map(subItem => (
                          <Link
                            href={subItem.link.href}
                            key={subItem.title}
                            className="mega-menu-link group relative flex h-48 w-96 flex-col justify-between rounded-lg border border-dark-background p-6"
                          >
                            <div className="flex flex-col">
                              <h5 className="text-body-bold z-10">{subItem.title}</h5>
                              <p className="text-input-label z-10 max-w-52 text-gray-4">{subItem.text}</p>
                            </div>
                            {subItem.link.text && <AnimatedLink className="z-10" linkText={subItem.link.text} />}

                            {subItem.bgImg && (
                              <Image alt="decorative" className="rounded-lg object-cover" src={subItem.bgImg} fill />
                            )}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        onMouseOver={handleMouseOutCloseMenu}
        className={clsx(
          'fixed inset-0 top-[calc(var(--navbarHeight)+var(--navbarBannerHeight))] hidden w-full overflow-hidden bg-[#1616173d] px-10 backdrop-blur-lg lg:block',
          menuOpenIdx != null ? 'visible' : 'invisible',
        )}
      ></div>
    </>
  );
};
export default DesktopNavbarDrawer;
