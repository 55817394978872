'use client';
import { ReactNode, useContext, createContext, useState, useEffect } from 'react';
import { hasCookie, setCookie } from 'cookies-next';

type BannerContextType = {
  isBannerOpen: boolean;
  handleCloseBanner: () => void;
};

const BannerContext = createContext<BannerContextType>({} as BannerContextType);

type BannerProviderProps = {
  children: ReactNode;
};

const BannerProvider = ({ children }: BannerProviderProps) => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);

  const handleCloseBanner = () => {
    setIsBannerOpen(false);
    // Set a cookie to remember the user's preference
    setCookie('announcement_banner_closed', true, {
      maxAge: 60 * 60 * 6, // 6 hours
    });
  };

  useEffect(() => {
    // Check if the user has already closed the banner
    setIsBannerOpen(!hasCookie('announcement_banner_closed'));
  }, []);

  const value = { isBannerOpen, handleCloseBanner };
  return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>;
};

const useBannerState = () => {
  const context = useContext(BannerContext);
  if (context === undefined) {
    throw new Error('useBannerState must be used from within a BannerProvider');
  }
  return context;
};

export { BannerProvider, useBannerState };
