'use client';

import Footer from '@/components/Footer';
import Banner from '@/components/Banner';
import { useBannerState } from '@/lib/contexts/BannerContext';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { isProductPage } from '@/app/(mainWebsite)/develocity/product/_components/productPageHierarchy';
import NavigationBar from '@/components/NavigationBar';

export default function MainWebsiteLayout({ children }: { children: React.ReactNode }) {
  const path = usePathname();
  const { isBannerOpen, handleCloseBanner } = useBannerState();

  // we dont want the main header to be sticky on develocity product pages
  const isDevelocityProductPage = isProductPage(path);

  const classes = clsx('z-[101]', {
    'sticky top-0': !isDevelocityProductPage,
    // add relative positioning for z-index to work
    relative: isDevelocityProductPage,
  });

  return (
    <>
      <div className={classes}>
        <Banner handleCloseBanner={handleCloseBanner} isBannerOpen={isBannerOpen} />
        <NavigationBar />
      </div>

      <main className="bg-white" id="main">
        {children}
      </main>
      <Footer />
    </>
  );
}
