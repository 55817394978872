import { footerMenuList } from '@/components/Footer/footer.copy';
import Link from 'next/link';

export type FatFooterMenuList = {
  title: string;
  items: FatFooterMenuListItem[];
};

type FatFooterMenuListItem = {
  name: string;
  href: string;
};

const renderMenuItems = (menu: FatFooterMenuList, index: number) => {
  const renderSubmenuItem = (item: FatFooterMenuListItem, index: number) => {
    return (
      <li key={`${item.name}-${index}`} className="h-6">
        {item.href.includes('http') ? (
          <a href={item.href} target="_blank" rel="noopener" className="font-normal text-gray-7 hover:text-core-blue">
            {item.name}
          </a>
        ) : (
          <Link href={item.href} className="font-normal text-gray-7 hover:text-core-blue">
            {item.name}
          </Link>
        )}
      </li>
    );
  };

  return (
    <div key={`col-${index}`} className="mx-10 my-3 flex flex-col items-start justify-center md:my-5">
      <p className={'mb-3 flex items-center text-black'}>{menu.title}</p>

      <ul className="mb-4 flex flex-1 flex-col items-start justify-center">
        {menu.items.map((item, index) => renderSubmenuItem(item, index))}
      </ul>
    </div>
  );
};

const FatFooter = () => {
  return (
    <section className="text-31xl font-h2-lg overflow-x:hidden box-border flex h-auto w-full flex-col items-start justify-center bg-light-background-200 py-4 text-center text-gray-1 md:flex-row">
      {footerMenuList.map((menu, index) => renderMenuItems(menu, index))}
    </section>
  );
};

export default FatFooter;
