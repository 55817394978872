'use client';

import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type BannerProps = {
  handleCloseBanner: () => void;
  isBannerOpen: boolean;
};

const Banner = ({ handleCloseBanner, isBannerOpen }: BannerProps) => {
  return (
    <div
      className={clsx(
        'bg-custom-gradient overflow-hidden transition-all duration-500',
        isBannerOpen ? 'max-h-[--navbarBannerMobileHeight]' : 'max-h-0',
      )}
    >
      <div className="max-w-section flex h-[--navbarBannerMobileHeight] items-center justify-center px-3 py-[0.625rem] text-center text-body-md text-white md:h-[--navbarBannerHeight]">
        <p className="block w-full md:hidden">
          <a href="https://dpeuniversity.gradle.com/" className="mega-menu-link underline">
            Try our free
          </a>{' '}
          Gradle and Maven training courses on <span className="whitespace-nowrap">DPE University</span>
        </p>
        <p className="hidden w-full md:block">
          Introducing{' '}
          <a href="https://dpeuniversity.gradle.com/" className="mega-menu-link underline">
            DPE University
          </a>{' '}
          — Explore free, self-paced training courses on <span className="whitespace-nowrap">Gradle and Maven</span>
        </p>
        <div
          onClick={handleCloseBanner}
          className="cursor-pointer rounded-full p-1 transition-colors hover:bg-gradle-blue"
        >
          <XMarkIcon className="size-5 transition-transform md:size-4" />
        </div>
      </div>
    </div>
  );
};
export default Banner;
