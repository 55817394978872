export type ProductPageHierarchyItem = {
  title: string;
  href: string;
};

export const pillarPages = ['accelerate', 'troubleshoot', 'observe'] as const;
type PillarPages = (typeof pillarPages)[number];

export type ProductPageHierarchy = {
  [key in PillarPages]: {
    pillarPage: ProductPageHierarchyItem;
    featurePages: ProductPageHierarchyItem[];
  };
};

export const productPageHierarchy: ProductPageHierarchy = {
  accelerate: {
    pillarPage: {
      title: 'Accelerate',
      href: '/develocity/accelerate',
    },
    featurePages: [
      {
        title: 'Build Cache',
        href: '/develocity/product/build-cache',
      },
      {
        title: 'Predictive Test Selection',
        href: '/develocity/product/predictive-test-selection',
      },
      {
        title: 'Test Distribution',
        href: '/develocity/product/test-distribution',
      },
    ],
  },
  troubleshoot: {
    pillarPage: {
      title: 'Troubleshoot',
      href: '/develocity/troubleshoot',
    },
    featurePages: [
      {
        title: 'Build Scan',
        href: '/develocity/product/build-scan',
      },
      {
        title: 'Flaky Test Detection',
        href: '/develocity/product/flaky-test-detection',
      },
      {
        title: 'Failure Analytics',
        href: '/develocity/product/failure-analytics',
      },
    ],
  },
  observe: {
    pillarPage: {
      title: 'Observe',
      href: '/develocity/observe',
    },
    featurePages: [
      {
        title: 'Reporting & Visualization',
        href: '/develocity/product/reporting-and-visualization',
      },
      {
        title: 'Performance Insights',
        href: '/develocity/product/performance-insights',
      },
    ],
  },
};

export function isProductPage(href: string): boolean {
  //ignore ids and trailing slashes

  const normalizeHref = (href: string) => href.replace(/\/$/, '').split('#')[0];
  const normalizedHref = normalizeHref(href);

  const isFeaturePage = Object.values(productPageHierarchy).some(pillar =>
    pillar.featurePages.some(page => normalizeHref(page.href) === normalizedHref),
  );

  const isPillarPage = Object.values(productPageHierarchy).some(
    pillar => normalizeHref(pillar.pillarPage.href) === normalizedHref,
  );

  return isFeaturePage || isPillarPage;
}
